import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AddDigitalAssetRequest, AddDigitalAssetResponse } from '@types';
import { API_URL, DIGITAL_ASSETS_API_REDUCER_KEY } from '@/constants';
import { RootState } from '@store';

export const digitalAssetsApi = createApi({
  reducerPath: DIGITAL_ASSETS_API_REDUCER_KEY,
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/v1/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authSlice.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    addDigitalAsset: builder.query<
      AddDigitalAssetResponse,
      AddDigitalAssetRequest
    >({
      query: (body) => {
        return {
          url: 'assets/digital',
          method: 'POST',
          body,
        };
      },
    }),
  }),
});
