import React, { ReactElement, type PropsWithChildren } from 'react';

type ConnectAction = {
  type: 'connect';
  wallet: string | string[];
  balance: string;
  chainId: string | null;
};
type DisconnectAction = { type: 'disconnect' };
type PageLoadedAction = {
  type: 'pageLoaded';
  isMetaMaskInstalled: boolean;
  wallet: string | string[] | null;
  balance: string | null;
  chainId: string | null;
};
type LoadingAction = { type: 'loading' };
type IdleAction = { type: 'idle' };

type Action =
  | ConnectAction
  | DisconnectAction
  | PageLoadedAction
  | LoadingAction
  | IdleAction;

type Dispatch = (action: Action) => void;

type Status = 'loading' | 'idle' | 'pageNotLoaded';

type State = {
  wallet: string | string[] | null;
  isMetaMaskInstalled: boolean;
  status: Status;
  balance: string | null;
  chainId: string | null;
};

const initialState: State = {
  wallet: null,
  isMetaMaskInstalled: false,
  status: 'loading',
  balance: null,
  chainId: null,
} as const;

function metamaskReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'connect': {
      const { wallet, balance, chainId } = action;
      const newState = {
        ...state,
        wallet,
        balance,
        status: 'idle',
        chainId,
      } as State;
      const info = JSON.stringify(newState);
      window.localStorage.setItem('metamaskState', info);

      return newState;
    }
    case 'disconnect': {
      window.localStorage.removeItem('metamaskState');
      if (typeof window.ethereum !== undefined) {
        window.ethereum.removeAllListeners(['accountsChanged']);
      }
      return { ...state, wallet: null, balance: null, chainId: null };
    }
    case 'pageLoaded': {
      const { isMetaMaskInstalled, balance, wallet, chainId } = action;
      return {
        ...state,
        isMetaMaskInstalled,
        status: 'idle',
        wallet,
        balance,
        chainId,
      };
    }
    case 'loading': {
      return { ...state, status: 'loading' };
    }
    case 'idle': {
      return { ...state, status: 'idle' };
    }

    default: {
      throw new Error('Unhandled action type');
    }
  }
}

const MetaMaskContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function MetaMaskProvider({ children }: PropsWithChildren): ReactElement {
  const [state, dispatch] = React.useReducer(metamaskReducer, initialState);
  const value = { state, dispatch };

  return (
    <MetaMaskContext.Provider value={value}>
      {children}
    </MetaMaskContext.Provider>
  );
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useMetaMask() {
  const context = React.useContext(MetaMaskContext);
  if (context === undefined) {
    throw new Error('useMetaMask must be used within a MetaMaskProvider');
  }
  return context;
}

export { MetaMaskProvider, useMetaMask };
