export const API_URL =
  process.env.REACT_APP_API_URL || 'https://api.dev.lkym.sehlat.io';
export const RESET_STATE_ACTION_TYPE = 'resetState';
export const COMMON_API_REDUCER_KEY = 'commonApi';
export const AUTH_API_REDUCER_KEY = 'authApi';
export const USER_API_REDUCER_KEY = 'userApi';
export const DASHBOARD_API_REDUCER_KEY = 'dashboardApi';
export const CHECKING_ACCOUNTS_API_REDUCER_KEY = 'checkingAccountsApi';
export const SAVING_ACCOUNTS_API_REDUCER_KEY = 'savingAccountsApi';
export const INVESTMENT_ACCOUNTS_API_REDUCER_KEY = 'investmentAccountsApi';
export const DIGITAL_ASSETS_API_REDUCER_KEY = 'digitalAssetsApi';
export const RARE_COLLECTIBLE_API_REDUCER_KEY = 'rareCollectibleApi';
