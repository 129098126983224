import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CollectibleItemDetailsType,
  CollectibleItemType,
  RareCollectibleSliceState,
  WatchBrandType,
  WatchModelType,
} from '@types';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState: RareCollectibleSliceState = {};

export const rareCollectibleSlice = createSlice({
  name: 'rareCollectibleSlice',
  initialState,
  reducers: {
    setWatchBrands: (state, action: PayloadAction<WatchBrandType[]>) => {
      state.watchBrands = action.payload;
    },
    setWatchModels: (state, action: PayloadAction<WatchModelType[]>) => {
      state.watchModels = action.payload;
    },
    setCollectibleItems: (
      state,
      action: PayloadAction<CollectibleItemType[]>,
    ) => {
      state.collectibleItems = action.payload;
    },
    setSelectedCollectibleItemDetails: (
      state,
      action: PayloadAction<CollectibleItemDetailsType>,
    ) => {
      state.collectibleItemDetails = action.payload;
    },
  },
});

export const rareCollectibleReducer = persistReducer(
  {
    key: 'rtk:rare-collectible',
    storage,
    whitelist: ['watchBrands', 'watchModels', 'collectibleItemDetails'],
  },
  rareCollectibleSlice.reducer,
);
