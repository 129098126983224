import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllAssetsType, AssetsDetails, DashboardSliceState } from '@types';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState: DashboardSliceState = {};

export const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {
    setAssetSummary: (
      state,
      action: PayloadAction<AllAssetsType & AssetsDetails>,
    ) => {
      state.assetSummary = action.payload;
    },
  },
});

export const dashboardReducer = persistReducer(
  {
    key: 'rtk:dashboard',
    storage,
    whitelist: ['assetSummary'],
  },
  dashboardSlice.reducer,
);
