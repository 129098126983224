import { TypographyOptions } from '@mui/material/styles/createTypography';
import verlagWoff2 from '../../assets/fonts/woff2/VerlagSSm-Book_Web.woff2';

export const typography: TypographyOptions = {
  fontFamily: ['HCo Verlag SSm', 'Arial'].join(','),
  h3: {
    fontFamily: 'HCo Verlag SSm',
    fontSize: 16,
    fontWeight: 400,
  },
};

export const fontComponents = {
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'HCo Verlag SSm';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: local('HCo Verlag SSm'), url(${verlagWoff2}) format('woff2');
      }
    `,
  },
};
export default typography;
