import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetAssetSummaryResponse } from '@types';
import { API_URL, INVESTMENT_ACCOUNTS_API_REDUCER_KEY } from '@/constants';
import { RootState } from '@store';

export const investmentAccountsApi = createApi({
  reducerPath: INVESTMENT_ACCOUNTS_API_REDUCER_KEY,
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/v1/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authSlice.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAssetSummary: builder.query<GetAssetSummaryResponse, unknown>({
      keepUnusedDataFor: 30,
      query: () => {
        return {
          url: 'assets/summary',
          method: 'GET',
        };
      },
    }),
  }),
});
