import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetMeResponse } from '@types';
import { API_URL, USER_API_REDUCER_KEY } from '@/constants';
import { RootState } from '@store';

export const userApi = createApi({
  reducerPath: USER_API_REDUCER_KEY,
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authSlice.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getMe: builder.query<GetMeResponse, unknown>({
      query: () => {
        return {
          url: '/api/current_user',
          method: 'GET',
        };
      },
    }),
  }),
});
