import React, { PropsWithChildren, Suspense, useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import Loader from '@components/atoms/Loader/Loader';
import routes from './routes';
import { instantiateSdk } from './utils/MetamaskSdk';
import { useListen } from './hooks/useListen';
import { useMetaMask } from './hooks/useMetaMask';
import './styles/styles.css';
import NetworkChangeModal from '@components/molecules/NetworkChangeModal/NetworkChangeModal';
import { Typography, Button as MuiButton } from '@mui/material';

export const App: React.FC<PropsWithChildren> = () => {
  const { dispatch: metaDispatch, state } = useMetaMask();
  const listen = useListen();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== undefined) {
      // start by checking if window.ethereum is present, indicating a wallet extension
      const ethereumProviderInjected = typeof window.ethereum !== 'undefined';
      // this could be other wallets so we can verify if we are dealing with metamask
      // using the boolean constructor to be explecit and not let this be used as a falsy value (optional)
      const isMetaMaskInstalled =
        ethereumProviderInjected && Boolean(window.ethereum.isMetaMask);

      const local = window.localStorage.getItem('metamaskState');

      // user was previously connected, start listening to MM
      if (local) {
        listen();
      }

      // local could be null if not present in LocalStorage
      const { wallet, balance, chainId } = local
        ? JSON.parse(local)
        : // backup if local storage is empty
          { wallet: null, balance: null, chainId: null };

      instantiateSdk();
      metaDispatch({
        type: 'pageLoaded',
        isMetaMaskInstalled,
        wallet,
        balance,
        chainId,
      });
    }
  }, []);

  useEffect(() => {
    if (state.chainId && state.chainId !== '0x1') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [state.chainId]);

  return (
    <div className="app-container">
      <Suspense fallback={<Loader />}>{useRoutes([...routes])}</Suspense>
      <NetworkChangeModal
        open={open}
        title={<Typography textAlign="center">Network info</Typography>}
        onClose={() => setOpen(false)}
      >
        <>
          <Typography
            fontSize="20px"
            lineHeight="26px"
            marginBottom="16px"
            fontWeight="400"
          >
            Switch your Metamask network to Ethereum Main Network
          </Typography>
          <Typography
            fontSize="14px"
            lineHeight="20px"
            marginBottom="32px"
            fontWeight="300"
          >
            Set your Metamask network to Ethereum Main Network so we can read
            your asset balance in the currency of ETH only.
          </Typography>
          <MuiButton
            fullWidth
            variant="contained"
            sx={{ borderRadius: 0 }}
            onClick={() => setOpen(false)}
          >
            Ok
          </MuiButton>
          <MuiButton
            fullWidth
            variant="outlined"
            sx={{ borderRadius: 0, marginTop: '16px' }}
            onClick={() => setOpen(false)}
          >
            Cancel
          </MuiButton>
        </>
      </NetworkChangeModal>
    </div>
  );
};

export default App;
