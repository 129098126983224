import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetAssetTypeResponse, GetCurrencyResponse } from '@types';
import { API_URL, COMMON_API_REDUCER_KEY } from '@/constants';
import { RootState } from '@store';
import { commonSlice } from '@store/slices/common';

export const commonApi = createApi({
  reducerPath: COMMON_API_REDUCER_KEY,
  keepUnusedDataFor: 300,
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authSlice.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAssetType: builder.query<GetAssetTypeResponse, unknown>({
      query: () => {
        return {
          url: '/api/v1/asset-types',
          method: 'GET',
        };
      },
    }),
    getCurrency: builder.query<GetCurrencyResponse, unknown>({
      query: () => {
        return {
          url: '/api/v1/currencies',
          method: 'GET',
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(commonSlice.actions.setCurrency(data.data));
        } catch (err) {
          // `onError` side-effect
          console.log(err);
        }
      },
    }),
  }),
});
