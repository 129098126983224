import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInfoType, UserSliceState } from '@types';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState: UserSliceState = {};

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserInfoType>) => {
      state.user = action.payload;
    },
  },
});

export const userReducer = persistReducer(
  {
    key: 'rtk:user',
    storage,
    whitelist: ['user'],
  },
  userSlice.reducer,
);
