import { createTheme } from '@mui/material/styles';
import { LinkProps } from '@mui/material/Link';
import { LinkBehavior } from './mui-routes';
import typography, { fontComponents } from './typography';

declare module '@mui/material/styles' {
  interface Palette {
    royalBlue: Palette['primary'];
    stone: Palette['primary'];
    reflexBlue: Palette['primary'];
  }
  interface PaletteOptions {
    royalBlue: PaletteOptions['primary'];
    stone: PaletteOptions['primary'];
    reflexBlue: PaletteOptions['primary'];
  }

  interface PaletteColor {
    darker?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
  }
}

export const theme = createTheme({
  typography,
  components: {
    ...fontComponents,
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
  palette: {
    mode: 'light',
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#141E55',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    royalBlue: {
      main: '#141E55',
    },
    stone: {
      main: '#B0AA7E',
    },
    reflexBlue: {
      main: '#001489',
    },

    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

export default theme;
