import { AddWatchI } from '@types';

export const formatNumber = (
  x: number,
  places = 2,
  country = 'de-CH',
): string => {
  return Number(x || 0).toLocaleString(country, {
    maximumFractionDigits: places,
    minimumFractionDigits: places,
  });
};

export const getNumberFormate = (amount: number, locals = 'en-SG'): string => {
  const formattedNumber = new Intl.NumberFormat(locals, {
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(amount);
  return formattedNumber;
};

export const getDateFormate = (date: string | number | Date): string => {
  const event = new Date(date);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const dateArray = event.toLocaleDateString('en-SG', options)?.split(' ');
  return `${dateArray[1]} ${dateArray[0]}, ${dateArray[2]}`;
};

export const isPositive = (num: number): boolean => {
  if (typeof num === 'number' && Math.sign(num) !== -1) {
    return true;
  }
  return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const objToArr = (obj: object = {}): any[] => {
  return Object.entries(obj).map(([k, v]) => ({ [k]: v }));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const prepareWatchData = (result: any): AddWatchI => {
  // const user = useTypedSelector((state: RootState) => state.userSlice.user);
  const data = {
    user_id: 1, // as currently one one user
    asset_type_id: 5,
    title: result.watch_name,
    description: result.description,
    item_type_id: 1,
    brand: result.brand_name,
    model: result.watch_model,
    quantity: 1,
    current_price: result.avg_price,
    currency_id: 1,
    price_range: `SGD ${result.min_price}  - SGD ${result.max_price}`,
  };
  return data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const enumToList = (enumList: any): Array<any> => {
  if (typeof enumList === 'object') {
    return Object.keys(enumList)
      .filter((value) => isNaN(Number(value)) === false)
      .map((key) => ({ id: key, value: enumList[key] }));
  }
  return [];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const listToOptions = (list: any, mapItems?: string[]): Array<any> => {
  if (typeof list === 'object') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return list.map((key: any) => ({
      label: mapItems ? key[mapItems[0]] : key.name || key.value,
      value: mapItems ? key[mapItems[1]] : +key.id,
    }));
  }
  return [];
};

export const getETHBalance = (balance: string): string => {
  return (parseInt(balance) / 1000000000000000000).toFixed(2);
};

export const avoidChars = '()&@!#%^*$';

export const avoidSpecialChar = (e: React.KeyboardEvent): void => {
  if (
    !(
      (e.keyCode > 95 && e.keyCode < 106) ||
      (e.keyCode > 47 && e.keyCode < 58) ||
      e.keyCode === 8
    ) ||
    avoidChars.includes(e.key)
  ) {
    e.preventDefault();
  }
};

export const avoidDecimal = (e: React.KeyboardEvent): void => {
  if (
    !(
      (e.keyCode > 95 && e.keyCode < 106) ||
      (e.keyCode > 47 && e.keyCode < 58) ||
      e.keyCode === 8 ||
      e.keyCode === 190
    ) ||
    avoidChars.includes(e.key)
  ) {
    e.preventDefault();
  }
};
