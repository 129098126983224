import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './loader.module.scss';

interface LoaderProps {
  text?: string;
}

export const Loader = ({ text }: LoaderProps): ReactElement => {
  return (
    <div className={styles.loaderWrapper}>
      <CircularProgress size={50}></CircularProgress>
      {!!text && <Typography variant="h3">{text}</Typography>}
    </div>
  );
};

export default Loader;
