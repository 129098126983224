import AuthorizedRoute from '@components/molecules/AuthorizedRoute';
import UnauthorizedRoute from '@components/molecules/UnauthorizedRoute';
import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Login = lazy(() => import('../pages/login'));
const Dashboard = lazy(() => import('../pages/dashboard'));
const AddAssets = lazy(() => import('../pages/add-assets/add-assets'));
const DigitalAssetPage = lazy(
  () => import('../pages/add-assets/digital-asset/digital-asset'),
);
const LinkDigitalWalletPage = lazy(
  () =>
    import(
      '../pages/add-assets/digital-asset/link-digital-wallet/link-digital-wallet'
    ),
);
const CheckingAccountsUPloadStatementPage = lazy(
  () =>
    import(
      '../pages/add-assets/checking-accounts/upload-statement/upload-statement'
    ),
);
const CheckingAccountsUPloadStatementSuccessPage = lazy(
  () =>
    import(
      '../pages/add-assets/checking-accounts/upload-statement/success/success'
    ),
);
const CheckingAccountsAddManuallyPage = lazy(
  () =>
    import('../pages/add-assets/checking-accounts/add-manually/add-manually'),
);
const RareCollectiblesPage = lazy(
  () => import('../pages/add-assets/rare-collectibles/rare-collectibles'),
);
const RareCollectiblesAddManuallyPage = lazy(
  () =>
    import('../pages/add-assets/rare-collectibles/add-manually/add-manually'),
);
const RareCollectiblesAddManuallyReviewPage = lazy(
  () =>
    import('../pages/add-assets/rare-collectibles/add-manually/review/review'),
);
const RareCollectiblesAddManuallySuccessPage = lazy(
  () =>
    import(
      '../pages/add-assets/rare-collectibles/add-manually/success/success'
    ),
);
const RareCollectiblesAddManuallyErrorPage = lazy(
  () =>
    import('../pages/add-assets/rare-collectibles/add-manually/error/error'),
);
const RareCollectiblesUploadPage = lazy(
  () =>
    import('../pages/add-assets/rare-collectibles/upload-image/upload-image'),
);
const RareCollectiblesUploadReviewPage = lazy(
  () =>
    import('../pages/add-assets/rare-collectibles/upload-image/review/review'),
);
const RareCollectiblesUploadSuccessPage = lazy(
  () =>
    import(
      '../pages/add-assets/rare-collectibles/upload-image/success/success'
    ),
);
const RareCollectiblesUploadErrorPage = lazy(
  () =>
    import('../pages/add-assets/rare-collectibles/upload-image/error/error'),
);
const DigitalAssetSuccessPage = lazy(
  () =>
    import(
      '../pages/add-assets/digital-asset/link-digital-wallet/success/success'
    ),
);
const DigitalAssetErrorPage = lazy(
  () =>
    import('../pages/add-assets/digital-asset/link-digital-wallet/error/error'),
);
const ContactPage = lazy(() => import('../pages/contact/contact'));
const CheckingAcounts = lazy(
  () => import('../pages/add-assets/checking-accounts/checking-accounts'),
);
const SuccessUploadStatement = lazy(
  () => import('../pages/add-assets/checking-accounts/add-manually/success'),
);
const CheckingAccountsDetailsPage = lazy(
  () => import('../pages/dashboard/checking-details/checking-details'),
);
const ErrorPage = lazy(() => import('../pages/error/error'));

const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <AuthorizedRoute>
        <Dashboard />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/login',
    element: (
      <UnauthorizedRoute>
        <Login />
      </UnauthorizedRoute>
    ),
  },
  {
    path: '/dashboard',
    element: (
      <AuthorizedRoute>
        <Dashboard />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/checking-account/details',
    element: (
      <AuthorizedRoute>
        <CheckingAccountsDetailsPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/add-assets',
    element: (
      <AuthorizedRoute>
        <AddAssets />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/add-assets/digital-asset',
    element: (
      <AuthorizedRoute>
        <DigitalAssetPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/add-assets/digital-asset/link-digital-wallet',
    element: (
      <AuthorizedRoute>
        <LinkDigitalWalletPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/add-assets/digital-asset/link-digital-wallet/success',
    element: (
      <AuthorizedRoute>
        <DigitalAssetSuccessPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/add-assets/digital-asset/link-digital-wallet/error',
    element: (
      <AuthorizedRoute>
        <DigitalAssetErrorPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/add-assets/rare-collectibles',
    element: (
      <AuthorizedRoute>
        <RareCollectiblesPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/add-assets/rare-collectibles/upload-image',
    element: (
      <AuthorizedRoute>
        <RareCollectiblesUploadPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/add-assets/rare-collectibles/upload-image/review',
    element: (
      <AuthorizedRoute>
        <RareCollectiblesUploadReviewPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/add-assets/rare-collectibles/upload-image/success',
    element: (
      <AuthorizedRoute>
        <RareCollectiblesUploadSuccessPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/add-assets/rare-collectibles/upload-image/error',
    element: (
      <AuthorizedRoute>
        <RareCollectiblesUploadErrorPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/add-assets/rare-collectibles/add-manually',
    element: (
      <AuthorizedRoute>
        <RareCollectiblesAddManuallyPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/add-assets/rare-collectibles/add-manually/review',
    element: (
      <AuthorizedRoute>
        <RareCollectiblesAddManuallyReviewPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/add-assets/rare-collectibles/add-manually/success',
    element: (
      <AuthorizedRoute>
        <RareCollectiblesAddManuallySuccessPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/add-assets/rare-collectibles/add-manually/error',
    element: (
      <AuthorizedRoute>
        <RareCollectiblesAddManuallyErrorPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/contact-us',
    element: (
      <AuthorizedRoute>
        <ContactPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/add-assets/checking-accounts',
    element: (
      <AuthorizedRoute>
        <CheckingAcounts />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/add-assets/checking-accounts/upload-statement',
    element: (
      <AuthorizedRoute>
        <CheckingAccountsUPloadStatementPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/add-assets/checking-accounts/upload-statement/success',
    element: (
      <AuthorizedRoute>
        <CheckingAccountsUPloadStatementSuccessPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/add-assets/checking-accounts/add-manually',
    element: (
      <AuthorizedRoute>
        <CheckingAccountsAddManuallyPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/add-assets/checking-accounts/add-manually/success',
    element: (
      <AuthorizedRoute>
        <SuccessUploadStatement />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/error',
    element: <ErrorPage />,
  },
  {
    path: '*',
    element: (
      <AuthorizedRoute>
        <Dashboard />
      </AuthorizedRoute>
    ),
  },
];

export default routes;
