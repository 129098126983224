import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { RootState, useTypedSelector } from '@store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AuthorizedRoute: React.FC<any> = ({ children }) => {
  const signInURL = '/login';
  const user = useTypedSelector((state: RootState) => state.userSlice.user);
  const location = useLocation();

  if (user?.id) {
    return children;
  } else {
    return (
      <Navigate
        //to={`${signInURL}?from=${location.pathname}`}
        to={{
          pathname: signInURL,
          search: `from=${location.pathname}`,
        }}
        state={`from: ${location}`}
        replace
      />
    );
  }
};

export default AuthorizedRoute;
