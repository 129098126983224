import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthRequest, AuthResponse } from '@types';
import { API_URL, AUTH_API_REDUCER_KEY } from '@/constants';

export const authApi = createApi({
  reducerPath: AUTH_API_REDUCER_KEY,
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}`,
  }),
  endpoints: (builder) => ({
    getAccessToken: builder.query<AuthResponse, AuthRequest>({
      query: (params) => {
        return {
          url: '/api/authenticate',
          method: 'POST',
          params,
        };
      },
    }),
  }),
});
