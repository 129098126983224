import { authReducer, authSlice } from './slices/auth';
import {
  commonApi,
  authApi,
  userApi,
  dashboardApi,
  checkingAccountsApi,
  savingAccountsApi,
  investmentAccountsApi,
  digitalAssetsApi,
  rareCollectibleApi,
} from '@/api';
import {
  COMMON_API_REDUCER_KEY,
  AUTH_API_REDUCER_KEY,
  USER_API_REDUCER_KEY,
  DASHBOARD_API_REDUCER_KEY,
  CHECKING_ACCOUNTS_API_REDUCER_KEY,
  SAVING_ACCOUNTS_API_REDUCER_KEY,
  INVESTMENT_ACCOUNTS_API_REDUCER_KEY,
  DIGITAL_ASSETS_API_REDUCER_KEY,
  RARE_COLLECTIBLE_API_REDUCER_KEY,
} from '@/constants';
import { commonReducer, commonSlice } from './slices/common';
import { userReducer, userSlice } from './slices/user';
import { dashboardReducer, dashboardSlice } from './slices/dashboard';
import {
  rareCollectibleReducer,
  rareCollectibleSlice,
} from './slices/rare-collectible';
import {
  checkingAccountReducer,
  checkingAccountSlice,
} from './slices/checking-account';

const reducers = {
  [commonSlice.name]: commonReducer,
  [authSlice.name]: authReducer,
  [userSlice.name]: userReducer,
  [dashboardSlice.name]: dashboardReducer,
  [checkingAccountSlice.name]: checkingAccountReducer,
  [rareCollectibleSlice.name]: rareCollectibleReducer,
  [COMMON_API_REDUCER_KEY]: commonApi.reducer,
  [AUTH_API_REDUCER_KEY]: authApi.reducer,
  [USER_API_REDUCER_KEY]: userApi.reducer,
  [DASHBOARD_API_REDUCER_KEY]: dashboardApi.reducer,
  [CHECKING_ACCOUNTS_API_REDUCER_KEY]: checkingAccountsApi.reducer,
  [SAVING_ACCOUNTS_API_REDUCER_KEY]: savingAccountsApi.reducer,
  [INVESTMENT_ACCOUNTS_API_REDUCER_KEY]: investmentAccountsApi.reducer,
  [DIGITAL_ASSETS_API_REDUCER_KEY]: digitalAssetsApi.reducer,
  [RARE_COLLECTIBLE_API_REDUCER_KEY]: rareCollectibleApi.reducer,
};

export default reducers;
