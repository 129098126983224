import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  AddCollectibleRequest,
  AddCollectibleResponse,
  GetAllWatchBrandProductsRequest,
  GetAllWatchBrandProductsResponse,
  GetAllWatchBrandsResponse,
  GetCollectibleItemsResponse,
  GetFileUrlRequest,
  GetFileUrlResponse,
  GetWatchBrandsResponse,
  GetWatchByReferenceRequest,
  GetWatchByReferenceResponse,
  GetWatchModelsRequest,
  GetWatchModelsResponse,
  IdentifyCollectibleRequest,
  IdentifyCollectibleResponse,
  UpdateCollectibleRequest,
  UpdateCollectibleResponse,
} from '@types';
import { API_URL, RARE_COLLECTIBLE_API_REDUCER_KEY } from '@/constants';
import { RootState } from '@store';
import { rareCollectibleSlice } from '@store/slices/rare-collectible';

export const rareCollectibleApi = createApi({
  reducerPath: RARE_COLLECTIBLE_API_REDUCER_KEY,
  keepUnusedDataFor: 300,
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/v1/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authSlice.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getWatchBrands: builder.query<GetWatchBrandsResponse, unknown>({
      query: () => {
        return {
          url: 'watch/brands',
          method: 'GET',
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(rareCollectibleSlice.actions.setWatchBrands(data.data));
        } catch (err) {
          // `onError` side-effect
          console.log(err);
        }
      },
    }),
    getCollectibleItems: builder.query<GetCollectibleItemsResponse, unknown>({
      query: () => {
        return {
          url: 'collectible-items',
          method: 'GET',
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(rareCollectibleSlice.actions.setCollectibleItems(data.data));
        } catch (err) {
          // `onError` side-effect
          console.log(err);
        }
      },
    }),
    getAllWatchBrands: builder.query<GetAllWatchBrandsResponse, unknown>({
      query: () => {
        return {
          url: 'watchsignals/brand/all',
          method: 'GET',
        };
      },
    }),
    getWatchModels: builder.query<
      GetWatchModelsResponse,
      GetWatchModelsRequest
    >({
      keepUnusedDataFor: 30,
      query: (arg) => {
        return {
          url: `watch/brands/${arg.brandId}/models`,
          method: 'GET',
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(rareCollectibleSlice.actions.setWatchModels(data.data));
        } catch (err) {
          // `onError` side-effect
          console.log(err);
        }
      },
    }),
    getCollectible: builder.query<
      AddCollectibleResponse,
      AddCollectibleRequest
    >({
      query: (body) => {
        return {
          url: `assets/collectible`,
          method: 'POST',
          body,
        };
      },
    }),
    addCollectible: builder.query<
      AddCollectibleResponse,
      AddCollectibleRequest
    >({
      query: (body) => {
        return {
          url: `assets/collectible`,
          method: 'POST',
          body,
        };
      },
    }),
    updateCollectible: builder.query<
      UpdateCollectibleResponse,
      UpdateCollectibleRequest
    >({
      query: (body: Partial<UpdateCollectibleRequest>) => {
        const params = { ...body };
        delete params.id;
        return {
          url: `assets/collectible/${body.id}`,
          method: 'PATCH',
          body,
        };
      },
    }),
    identifyCollectible: builder.query<
      IdentifyCollectibleResponse,
      IdentifyCollectibleRequest
    >({
      query: (body) => {
        return {
          url: `assets/collectible/identify`,
          method: 'POST',
          body,
        };
      },
    }),
    getWatchByReference: builder.query<
      GetWatchByReferenceResponse,
      GetWatchByReferenceRequest
    >({
      query: (params) => {
        return {
          url: `watch/product/${params.reference}`,
          method: 'GET',
        };
      },
    }),
    getAllWatchBrandProducts: builder.query<
      GetAllWatchBrandProductsResponse,
      GetAllWatchBrandProductsRequest
    >({
      query: (params) => {
        return {
          url: `watchsignals/brand/referencenumber/${params.brand_id}`,
          method: 'GET',
        };
      },
    }),
    getFileUrl: builder.query<GetFileUrlResponse, GetFileUrlRequest>({
      query: (params) => {
        return {
          url: `upload/getFileUrl?file=${params.filename}`,
          method: 'GET',
        };
      },
    }),
  }),
});
