import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AssetClassType,
  BankNameType,
  checkingAccountSliceState,
} from '@types';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState: checkingAccountSliceState = {};

export const checkingAccountSlice = createSlice({
  name: 'checkingAccountSlice',
  initialState,
  reducers: {
    setBankName: (state, action: PayloadAction<BankNameType[]>) => {
      state.bankNameType = action.payload;
    },
    setAssetClass: (state, action: PayloadAction<AssetClassType[]>) => {
      state.assetClassType = action.payload;
    },
  },
});

export const checkingAccountReducer = persistReducer(
  {
    key: 'rtk:checking-account',
    storage,
    whitelist: ['bankNameType', 'assetClassType'],
  },
  checkingAccountSlice.reducer,
);
