import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  AddCheckingAccountRequest,
  AddCheckingAccountResponse,
  GetAllBankResponse,
  GetAssetClassRequest,
  GetAssetClassResponse,
  GetCheckingAccountRequest,
  GetCheckingAccountResponse,
  UpdateCheckingAccountRequest,
  UpdateCheckingAccountResponse,
} from '@types';
import { API_URL, CHECKING_ACCOUNTS_API_REDUCER_KEY } from '@/constants';
import { RootState } from '@store';
import { checkingAccountSlice } from '@store/slices/checking-account';

export const checkingAccountsApi = createApi({
  reducerPath: CHECKING_ACCOUNTS_API_REDUCER_KEY,
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/v1/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authSlice.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllBank: builder.query<GetAllBankResponse, unknown>({
      keepUnusedDataFor: 30,
      query: () => {
        return {
          url: 'assets/checking/banks',
          method: 'GET',
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(checkingAccountSlice.actions.setBankName(data.data));
        } catch (err) {
          // `onError` side-effect
          console.log(err);
        }
      },
    }),
    getAssetClass: builder.query<GetAssetClassResponse, GetAssetClassRequest>({
      keepUnusedDataFor: 30,
      query: (params) => {
        return {
          url: `asset-types/${params.asset_type_id}/asset-class`,
          method: 'GET',
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(checkingAccountSlice.actions.setAssetClass(data.data));
        } catch (err) {
          // `onError` side-effect
          console.log(err);
        }
      },
    }),
    addAsset: builder.query<
      AddCheckingAccountResponse,
      AddCheckingAccountRequest
    >({
      query: (body) => {
        return {
          url: `assets/checking`,
          method: 'POST',
          body,
        };
      },
    }),
    updateAsset: builder.query<
      UpdateCheckingAccountResponse,
      UpdateCheckingAccountRequest
    >({
      query: (body) => {
        return {
          url: `assets/checking/${body.id}`,
          method: 'PATCH',
          body: {
            balance: body.balance,
          },
        };
      },
    }),
    getAsset: builder.query<
      GetCheckingAccountResponse,
      GetCheckingAccountRequest
    >({
      keepUnusedDataFor: 30,
      query: (params) => {
        return {
          url: `assets/checking/${params.account_number}`,
          method: 'GET',
          params: {
            currency_id: params.currency_id,
          },
        };
      },
    }),
  }),
});
