import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetAssetSummaryResponse } from '@types';
import { API_URL, DASHBOARD_API_REDUCER_KEY } from '@/constants';
import { RootState } from '@store';
import { dashboardSlice } from '@store/slices/dashboard';

export const dashboardApi = createApi({
  reducerPath: DASHBOARD_API_REDUCER_KEY,
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authSlice.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAssetSummary: builder.query<GetAssetSummaryResponse, unknown>({
      keepUnusedDataFor: 30,
      query: () => {
        return {
          url: '/api/v1/assets/summary',
          method: 'GET',
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(dashboardSlice.actions.setAssetSummary(data.data));
        } catch (err) {
          // `onError` side-effect
          console.log(err);
        }
      },
    }),
  }),
});
