import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssetType, CommonSliceState, CurrencyType } from '@types';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState: CommonSliceState = {
  loading: false,
};

export const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    setGlobalLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAssetType: (state, action: PayloadAction<AssetType[]>) => {
      state.assetType = action.payload;
    },
    setCurrency: (state, action: PayloadAction<CurrencyType[]>) => {
      state.currency = action.payload;
    },
  },
});

export const commonReducer = persistReducer(
  {
    key: 'rtk:common',
    storage,
    whitelist: ['loading', 'assetType'],
  },
  commonSlice.reducer,
);
