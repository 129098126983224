import { digitalAssetsApi } from '@/api';
import { getETHBalance } from '@/utils/utils';
import { RootState, useTypedSelector } from '@store';
import { useMetaMask } from './useMetaMask';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useListen = () => {
  const { dispatch, state } = useMetaMask();
  const user = useTypedSelector((state: RootState) => state.userSlice.user);
  const [trigger] = digitalAssetsApi.endpoints.addDigitalAsset.useLazyQuery();

  return () => {
    window.ethereum.on('accountsChanged', async (newAccounts: string[]) => {
      const chainId = await window.ethereum.request({ method: 'eth_chainId' });
      if (newAccounts.length > 0) {
        // uppon receiving a new wallet, we'll request again the balance to synchronize the UI.
        const newBalance = await window.ethereum?.request({
          method: 'eth_getBalance',
          params: [newAccounts[0], 'latest'],
        });

        dispatch({
          type: 'connect',
          wallet: newAccounts[0],
          balance: newBalance,
          chainId: chainId,
        });
        if (chainId === '0x1') {
          const digitalAsset = {
            user_id: user?.id || 1,
            asset_type_id: 4, // For one only metamask
            name: user?.username || 'Admin',
            wallet_type: 'Metamask', // For now STATIC
            wallet_address: newBalance,
            currency: 'ETH', // For now STATIC
            balance: +getETHBalance(newBalance),
          };
          trigger(digitalAsset);
        }
      } else {
        // if the length is 0, then the user has disconnected from the wallet UI
        dispatch({ type: 'disconnect' });
      }
    });
    window.ethereum.on('chainChanged', async (_chainId: string) => {
      dispatch({
        type: 'connect',
        wallet: state.wallet || '',
        balance: state.balance || '',
        chainId: _chainId,
      });
    });
  };
};
