import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React, { ReactElement, ReactNode } from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

interface NetworkChangeModalProps {
  open?: boolean;
  fullScreen?: boolean;
  title?: ReactNode;
  children?: ReactNode;
  actions?: ReactNode;
  onClose?: () => void;
  [key: string]: unknown;
}
const NetworkChangeModal = ({
  open = false,
  fullScreen = false,
  title,
  children,
  actions,
  onClose,
}: NetworkChangeModalProps): ReactElement => {
  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      fullWidth
      sx={{
        '& .MuiDialog-container .MuiDialog-paperFullWidth': {
          background: '#f6f6f6',
          width: '100%',
          maxHeight: '100%',
          margin: '0px auto',
          position: 'absolute',
          bottom: 0,
        },
      }}
    >
      {title ? (
        <DialogTitle
          sx={{ background: '#fff', borderBottom: '0.5px solid #E0E0E0' }}
        >
          {title}
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
      ) : null}
      <DialogContent
        sx={{
          background: '#fff',
          '&.MuiDialogContent-root': { margin: '0', padding: '24px' },
        }}
      >
        {children}
      </DialogContent>
      {actions ? <DialogActions></DialogActions> : null}
    </Dialog>
  );
};

export default NetworkChangeModal;
