import React from 'react';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { RootState, useTypedSelector } from '@store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UnauthorizedRoute: React.FC<any> = ({ children, redirectTo = '/' }) => {
  const location = useLocation();
  const user = useTypedSelector((state: RootState) => state.userSlice.user);

  const from = new URLSearchParams(location.search).get('from') || redirectTo;

  if (!user?.id) {
    return children;
  } else {
    return <Navigate to={from} replace />;
  }
};

export default UnauthorizedRoute;
